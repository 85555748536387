<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Terminal">
          <form-adicionar-contrato
            v-if="mostrarFormAdicionar || irParaCadastro"
            :perfilContrato="perfilContrato"
            @adicionado="mostrarFormAdicionar = false"
          />
          <form-editar-contrato
            v-else-if="editarContrato"
            v-model="contratoSelecionado"
          />
          <listar-contratos
            v-else
            v-model="prestadoresServico"
            :tela="permission"
            title="Prestadores de serviço registrados"
            titleNew="Novo Prestador de Serviço"
            :perfilContrato="perfilContrato"
            @mostrarFormAdicionar="mostrarFormAdicionar = true"
            @editar="editar"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListarContratos from '@/components/contrato/ListarContratos'
import FormAdicionarContrato from '@/components/contrato/adicionar/FormAdicionarContrato.vue'
import FormEditarContrato from '@/components/contrato/editar/FormEditarContrato.vue'
import Perfil from '@/utils/perfil'
import { BasicPermissions, Sequences } from "@/utils/permissions"

export default {
  props: {
    irParaCadastro: {
      default: false,
    },
  },
  data() {
    return {
      Sequences,
      mostrarFormAdicionar: false,
      editarContrato: false,
      page: {
        title: 'Gerenciamento de prestadores de servico',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Prestadores de serviço',
          disabled: true,
        },
      ],
      perfilContrato: Perfil.Prestador,
      contratoSelecionado: {},
    }
  },
  components: {
    ListarContratos,
    FormAdicionarContrato,
    FormEditarContrato,
  },
  computed: {
    ...mapGetters('contratos', ['prestadoresServico']),

    permission() {
      return Sequences.Prestadores.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      try {
        await this.getContratos()
      } catch (e) {
        this.errorMessage('Erro ao carregar lista de empresas contratadas')
      }
    }   
  },
  methods: {
    ...mapActions('contratos', ['getContratos']),
    ...mapActions('template', ['errorMessage']),

    editar(contrato) {
      this.contratoSelecionado = contrato
      this.editarContrato = true
    },
  },
}
</script>

<style></style>
