<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Sobre a empresa
      </h3>
      <span class="red--text" v-if="!novaEmpresa"
        >Dados editáveis apenas pelo administrador da empresa</span
      >
    </v-card-text>
    <validation-observer ref="observer">
      <v-form :readonly="!novaEmpresa">
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="Nome Fantasia"
                rules="required"
              >
                <v-text-field
                  label="Nome Fantasia"
                  :error-messages="errors"
                  v-model="empresa.business_name"
                  :disabled="disabled"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <v-text-field
                  label="Razão Social"
                  :error-messages="errors"
                  v-model="empresa.trade_name"
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-if="!!empresa.cnpj"
                v-slot="{ errors }"
                name="CNPJ"
                rules="required|cnpjValido|cnpjNaoEmpresaAtual"
              >
                <v-text-field
                  label="CNPJ"
                  :error-messages="errors"
                  readonly
                  v-model="empresa.cnpj"
                  disabled
                  v-mask="['##.###.###/####-##']"
                />
              </validation-provider>
              <validation-provider v-else v-slot="{ errors }" name="CPF">
                <v-text-field
                  label="CPF"
                  :error-messages="errors"
                  readonly
                  v-model="empresa.cpf"
                  disabled
                  v-mask="['###.###.###-##']"
                />
              </validation-provider>
              <v-text-field
                :disabled="disabled"
                label="IE"
                v-model="empresa.ie"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pa-5">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Endereço
          </h3>
          <v-row class="mt-3">
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Endereço"
                rules="required"
              >
                <v-text-field
                  label="Endereço"
                  :error-messages="errors"
                  v-model="empresa.address"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Número"
                rules="required"
              >
                <v-text-field
                  label="Número"
                  :error-messages="errors"
                  v-model="empresa.numero"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Bairro"
                rules="required"
              >
                <v-text-field
                  label="Bairro"
                  :error-messages="errors"
                  v-model="empresa.district"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Complemento"
                rules="max:50"
              >
                <v-text-field
                  label="Complemento"
                  :error-messages="errors"
                  v-model="empresa.complemento"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Cidade"
                rules="required"
              >
                <v-text-field
                  label="Cidade"
                  :error-messages="errors"
                  v-model="empresa.city"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="CEP"
                rules="required"
              >
                <v-text-field
                  label="CEP"
                  :error-messages="errors"
                  v-mask="['#####-###']"
                  v-model="empresa.zip_code"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Estado"
                rules="required"
              >
                <v-select
                  label="Estado"
                  :error-messages="errors"
                  :items="estados"
                  v-model="empresa.state"
                  :disabled="disabled"
                  item-text="nome"
                  item-value="nome"
                />
              </validation-provider>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <validation-provider
                v-slot="{ errors }"
                name="Telefone"
                rules="required"
              >
                <v-text-field
                  label="Telefone"
                  :error-messages="errors"
                  v-model="empresa.phone"
                  :disabled="disabled"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.rntrc_numero"
                label="Numero RNTRC"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
                label="Tipo RNTRC"
                :items="['CTC', 'ETC', 'TAC']"
                v-model="value.rntrc_tipo"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.rntrc_expiration_date"
                label="Data de validade RNTRC"
                persistent-hint
                type="date"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'

import UtilsApi from '@/services/utils/utils.api'

export default {
  mixins: [validationMixin],

  props: {
    disabled: Boolean,
    empresaEdita: Object,
    value: {
      type: Object,
      required: true,
    },
    novaEmpresa: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      empresa: {},
      estados: [],
    }
  },
  watch: {
    value(val) {
      this.empresa = val
      this.$forceUpdate()
    },
  },
  methods: {
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
  },
  created() {
    this.getEstados()
    this.empresa = this.value
    console.log(this.value)
    this.$forceUpdate()
  },
}
</script>
