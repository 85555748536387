<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações do contrato com a empresa
      </h3>
    </v-card-text>
    <v-card-text class="pa-5 border-bottom">
      <v-form>
        <div class="mt-4">
          <v-data-table
            :headers="headers"
            :items="contatos"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Contatos registrados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!showFormContato"
                  color="primary"
                  dark
                  class="mb-2"
                  :disabled="disabled"
                  @click="showFormContato = !showFormContato"
                  >Novo contato</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editarContato(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="excluirContato(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <span>Sem contatos adicionados</span>
            </template>
          </v-data-table>
          <v-form v-if="showFormContato">
            <v-row>
              <v-toolbar flat color="white">
                <v-toolbar-title>{{titleForm}}</v-toolbar-title>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" lg="6" class="pt-0">
                <v-text-field
                  label="Nome"
                  required
                  v-model="contatoAtual.nome"
                  :disabled="disabled"
                ></v-text-field>
                <v-text-field
                  label="Telefone"
                  v-model="contatoAtual.telefone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="6" class="pt-0">
                <v-text-field
                  label="Email"
                  v-model="contatoAtual.email"
                  :disabled="disabled"
                />
                <v-text-field
                  label="Setor"
                  v-model="contatoAtual.descricao"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="pa-5 border-top text-right">
              <v-btn
                color="info"
                class="mr-2 text-capitalize"
                @click="salvarContato"
                :disabled="disabled"
                >{{textoBotaoSalvar}}</v-btn
              >
              <v-btn
                color="black"
                class="text-capitalize"
                dark
                @click="reset"
                >Cancelar</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contatos: this.value.contatos,
      contatoAtual: {},
      showFormContato: false,
      editandoContato: false,
      editIndex: -1,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'nome',
        },
        { text: 'E-mail', value: 'email' },
        { text: 'Telefone', value: 'telefone' },
        { text: 'Setor Responsável', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
      titleForm() {
          return this.editandoContato ? 'Edição de contato' : 'Novo contato'
      },
      textoBotaoSalvar() {
          return this.editandoContato ? 'Salvar contato' : 'Adicionar Contato'
      }
  },
  methods: {
    salvarContato() {
      if (this.editandoContato) {
          this.contatos.splice(this.editIndex, 1,  this.contatoAtual)
      } else {
          this.contatos.push(this.contatoAtual)
      }
      this.showFormContato = false
      this.editandoContato = false
      this.contatoAtual = {}
      this.update()
    },
    editarContato(contato) {
      this.editIndex = this.contatos.indexOf(contato)
      Object.assign(this.contatoAtual, contato)
      this.editandoContato = true
      this.showFormContato = true
    },
    excluirContato(contato) {
      const indiceContato = this.contatos.indexOf(contato)
      this.contatos.splice(indiceContato, 1)
      this.update()
    },
    update() {
      this.$emit(
        'atualizar-contatos',
        JSON.parse(JSON.stringify(this.contatos))
      )
    },
    reset() {
        this.showFormContato = false
        this.editandoContato = false
        this.contatoAtual = {}
    }
  },
}
</script>

<style>
</style>