<template>
  <div>
    <confirm-dialog @confirm="confirm" />
    <v-card v-if="formAdicionarAdminEmpresa">
      <FormFieldsAdicionarUsuario
        cadastroAdminEmpresa
        @close="formAdicionarAdminEmpresa = false"
        @save="adicionarContaAdministrativa"
      />
    </v-card>
    <div v-else class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-tabs v-model="tab">
        <v-tab href="#ativos" @change="$emit('update')">Ativos</v-tab>
        <v-tab href="#inativos" @change="$emit('updateInativos')"
          >Inativos</v-tab
        >
        <v-tabs-items v-model="tab" class="mt-1">
          <v-tab-item value="ativos">
            <v-data-table
              :headers="headers"
              :items="ativos"
              :search="search"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    medium
                    @click="$emit('update')"
                    title="Atualizar"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="canCreate && allowedToAdd"
                    color="primary"
                    dark
                    class="mb-2"
                    @click="$emit('mostrarFormAdicionar')"
                    >{{ titleNew }}</v-btn
                  >
                </v-toolbar>
              </template>
              <template v-slot:[`item.cnpj`]="{ item }">
                {{ item.cnpj | VMask('##.###.###/####-##') }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="canUpdate" small class="mr-2" v-bind="attrs" v-on="on"
                      >mdi-account-supervisor-circle</v-icon
                    >
                  </template>
                  <v-list v-if="item.admins && item.admins.length > 0">
                    <v-list-item
                      v-for="admin in item.admins"
                      :key="admin.user.id"
                    >
                      <v-list-item-title>{{
                        admin.user.email
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list v-else>
                    <v-list-item>Sem contas administrativas</v-list-item>
                    <v-list-item @click="abrirDialogoAdicionarConta(item)"
                      ><v-btn icon color="green"
                        ><v-icon>mdi-plus</v-icon>
                      </v-btn></v-list-item
                    >
                  </v-list>
                </v-menu>
                <v-icon
                  v-if="canUpdate"
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon v-if="canUpdate" color="error" small @click="deactivateItem(item)"
                  >mdi-cancel</v-icon
                >
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="inativos">
            <v-data-table
              :headers="headers"
              :items="inativos"
              :search="search"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    medium
                    @click="$emit('updateInativos')"
                    title="Atualizar"
                    >mdi-refresh</v-icon
                  >
                </v-toolbar>
              </template>
              <template v-slot:[`item.cnpj`]="{ item }">
                {{ item.cnpj | VMask('##.###.###/####-##') }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon v-if="canUpdate" color="error" small @click="activateItem(item)"
                  >mdi-undo</v-icon
                >
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import FormFieldsAdicionarUsuario from '@/components/gerencial/colaboradores/adicionarUsuario/FormFieldsAdicionarUsuario.vue'
// import { BasicPermissions } from "@/utils/permissions"


export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Empresas registradas',
    },
    titleNew: {
      type: String,
      default: 'Nova Empresa',
    },
    allowedToAdd: {
      type: Boolean,
      default: true,
    },
    perfilContrato: {
      type: Number,
      required: true,
    },
    tela: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formAdicionarAdminEmpresa: false,
      search: '',
      tab: 'ativos',
      contratoAtual: {},
      headers: [
        {
          text: 'Empresa',
          align: 'start',
          value: 'trade_name',
        },
        { text: 'Nome fantasia', value: 'business_name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Estado', value: 'state' },
        { text: 'Cidade', value: 'city' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },

  components: { ConfirmDialog, FormFieldsAdicionarUsuario },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    canList() {
      return true
      // return this.$canDo(BasicPermissions.VISUALIZAR, Sequences)
    },
    canCreate() {
      return true
      // return this.$canDo(BasicPermissions.CADASTRAR, this.tela)
    },
    canUpdate() {
      return true
      // return this.$canDo(BasicPermissions.EDITAR, this.tela)
    },
    canDelete() {
      return true
      // return this.$canDo(BasicPermissions.EXCLUIR, this.tela)
    },

    ativos() {
      return this.value.filter((o) => o.active)
    },
    inativos() {
      return this.value.filter((o) => !o.active)
    },
  },

  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('tenants', ['adicionarAdminEmpresa']),
    ...mapActions('contratos', ['desativarContrato', 'ativarContrato']),
    adicionar() {
      this.$emit('showForm')
    },

    editItem(item) {
      this.$emit('editar', item)
    },

    abrirDialogoAdicionarConta(item) {
      this.formAdicionarAdminEmpresa = true
      this.contratoAtual = item
    },
    async adicionarContaAdministrativa({ user }) {
      try {
        await this.adicionarAdminEmpresa({
          user,
          empresa: this.contratoAtual.cliente_id,
          grupo: this.contratoAtual.adminGroup,
        })
        this.successMessage('Conta administrativa adicionada com sucesso')
        this.$emit('update')
        this.formAdicionarAdminEmpresa = false
      } catch (e) {
        this.errorMessage('Erro ao adicionar conta administrativa')
      }
    },
    deactivateItem(item) {
      this.contratoAtual = item
      this.setDialogMessage({
        title: 'Confirmar inativação?',
        message: 'Deseja mesmo inativar essa empresa?',
      })
    },
    activateItem(item) {
      this.contratoAtual = item
      this.setDialogMessage({
        title: 'Confirmar ativação?',
        message: 'Deseja mesmo ativar essa empresa?',
      })
    },
    async confirm() {
      try {
        if (this.contratoAtual.active) {
          await this.desativarContrato(this.contratoAtual)
          this.successMessage('Contrato desativado com sucesso!')
        } else {
          await this.ativarContrato(this.contratoAtual)
          this.successMessage('Contrato ativado com sucesso!')
        }
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  },
}
</script>
