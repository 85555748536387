<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <slot name="prepend-tab" />
      <v-tab href="#dados">
        Dados da empresa <v-icon>mdi-home-city</v-icon></v-tab
      >
      <v-tab href="#contatos"> Contatos <v-icon>mdi-phone</v-icon></v-tab>
      <slot name="append-tab" />

      <v-tabs-items v-model="tab">
        <slot name="tab-item" />
        <v-tab-item value="dados">
          <dados-contratado v-model="value" />
        </v-tab-item>
        <v-tab-item value="contatos">
          <dados-contato
            v-model="value"
            @atualizar-contatos="atualizarContatos"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosContratado from './DadosContratado.vue'
import DadosContato from './DadosContato.vue'
import { mapActions } from 'vuex'
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: { DadosContratado, DadosContato },
  data() {
    return {
      tab: null,
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('contratos', ['atualizarContratos']),
    async atualizarContatos(contatos) {
      try {
        await this.atualizarContratos({
          contrato: this.value.public_id,
          contatos,
        })
        this.successMessage('Contato atualizado com sucesso')
        this.getContratos()
      } catch (e) {
        this.errorMessage('Erro ao atualizar lista de contatos')
      }
    },
  },
}
</script>

<style>
</style>
