<template>
  <v-row>
    <v-col :cols="cols" :sm="sm" :md="md" class="m-auto">
      <validation-provider
        v-slot="{ errors }"
        vid="password"
        :name="label"
        :rules="{ required: true, regex }"
      >
        <v-text-field
          type="password"
          @input="$emit('input', $event)"
          :value="value"
          :error-messages="errors"
          :label="label"
        />
        <p
          class="password-help-text text-left"
          v-for="(regra, index) in senhaAjuda"
          :key="index"
        >
          {{ regra }}
        </p>
      </validation-provider>
    </v-col>
    <v-col :cols="cols" :sm="sm" :md="md" class="m-auto">
      <validation-provider
        v-slot="{ errors }"
        name="Confirmação de Senha"
        rules="required|confirmed:password"
      >
        <v-text-field
          type="password"
          v-model="passwordConfirm"
          label="Confirmação de Senha"
          :error-messages="errors"
        ></v-text-field>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    cols: {
      required: true,
      type: Number,
    },
    sm: {
      required: true,
      type: Number,
    },
    md: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      passwordConfirm: '',
      senhaAjuda: [
        'No mínimo 8 caracteres',
        'No mínimo 1 número',
        'No mínimo 1 letra minúscula',
        'No mínimo 1 letra maiúscula',
      ],
    }
  },
  watch: {
    value: function (newVal) {
      if (!newVal) {
        this.resetPasswordConfirm()
      }
    },
  },
  methods: {
    resetPasswordConfirm: function () {
      this.passwordConfirm = ''
    },
  },
}
</script>

<style scoped>
.password-help-text::before {
  content: '\1F512';
}
</style>
