<template>
  <form :readonly="disabled" class="px-12">
    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <v-row>
            <!--  <v-row class="d-flex justify-center w-100">
              <v-col cols="12" sm="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Usuário"
                  rules="required|alpha_dash"
                >
                  <v-text-field
                    v-model="usuario.username"
                    label="Usuário"
                    :disabled="disabled"
                    :error-messages="
                      usernameJaCadastrado
                        ? 'Esse nome de usuário já está em uso'
                        : errors
                    "
                    @change="verificaDadoCadastradoChange($event, 'username')"
                  />
                </validation-provider>
              </v-col>
            </v-row> -->
            <v-row class="d-flex justify-center w-100">
              <!-- <v-col cols="12" sm="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="CPF"
                  :rules="`cpfValido${!usuario.cnpj ? '|required' : ''}`"
                >
                  <v-text-field
                    v-model="usuario.cpf"
                    ref="cpf"
                    label="CPffffffF"
                    :disabled="disabled"
                    v-mask="['###.###.###-##']"
                    :error-messages="
                      cpfJaCadastrado ? 'CPF já cadastrado' : errors
                    "
                    @change="verificaDadoCadastradoChange($event, 'cpf')"
                  ></v-text-field>
                </validation-provider>
              </v-col> -->
              <v-col cols="12" sm="12" md="2">
                <v-select
                  v-model="nacionalidade"
                  :items="nacionalidades"
                  label="Nacionalidade"
                  item-text="pais"
                  item-value="pais"
                  @blur="changeTypeMask(nacionalidade)"
                />
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <validation-provider v-slot="{ errors }" name="Documento">
                  <v-text-field
                    label="Documento"
                    :error-messages="errors"
                    required
                    v-model="usuario.cpf"
                    v-mask="mask"
                    @change="verificaDadoCadastradoChange($event, 'cpf')"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="E-mail"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="usuario.email"
                    label="E-mail"
                    :disabled="disabled"
                    :error-messages="
                      emailJaCadastrado
                        ? 'Já existe uma conta ssociada a esse email'
                        : errors
                    "
                    @change="verificaDadoCadastradoChange($event, 'email')"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome Completo"
                  rules="required|alpha_spaces"
                >
                  <v-text-field
                    v-model="usuario.name"
                    label="Nome Completo"
                    :disabled="disabled"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <!-- <v-row class="d-flex justify-center w-100">
              <v-col cols="12" sm="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="CNPJ"
                  :rules="`cnpjValido${!usuario.cpf ? '|required' : ''}`"
                >
                  <v-text-field
                    v-model="usuario.cnpj"
                    label="CNPJ"
                    :disabled="disabled"
                    v-mask="['##.###.###/####-##']"
                    :error-messages="
                      cnpjJaCadastrado ? 'CNPJ já cadastrado' : errors
                    "
                    @change="verificaDadoCadastradoChange($event, 'cnpj')"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row> -->

            <password
              v-if="!cpfJaCadastrado"
              class="d-flex justify-center w-100"
              v-model="usuario.password"
              label="Senha"
              :disabled="disabled"
              :cols="12"
              :sm="12"
              :md="6"
            />

            <v-row
              class="d-flex justify-center w-100"
              v-if="!cadastroAdminEmpresa"
            >
              <v-col cols="12" sm="12" md="6" class="m-auto">
                <validation-provider
                  v-slot="{ errors }"
                  name="Grupo"
                  rules="required"
                >
                  <v-select
                    label="Grupo"
                    :items="grupos"
                    :error-messages="errors"
                    v-model="grupo"
                    item-text="nome"
                    item-value="public_id"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="m-auto">
                <validation-provider
                  v-slot="{ errors }"
                  name="Perfis"
                  rules="required"
                >
                  <v-select
                    multiple
                    label="Perfis"
                    :items="perfisEmpresaAtual"
                    :error-messages="errors"
                    v-model="perfis"
                    item-text="nome"
                    item-value="cod"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="hasButton">
      <v-spacer></v-spacer>
      <buttons-cadastro
        @close="close"
        @save="save"
        :disabled="!($refs.observer && $refs.observer.flags.valid)"
      />
    </v-card-actions>
  </form>
</template>

<script>
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { mapActions, mapState } from 'vuex'
import Perfil from '@/utils/perfil'

import UsuarioApi from '../../../../services/usuario/index'
import ButtonsCadastro from '../../../ButtonsCadastro/ButtonsCadastro.vue'
import Password from '../../../Password/Password.vue'

export default {
  components: { ButtonsCadastro, Password },
  props: {
    tipo: {
      type: Number,
      required: false,
    },
    cadastroAdminEmpresa: {
      type: Boolean,
    },
    tenantAdminEmpresa: {
      type: Object,
    },
    hasButton: {
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usuario: {
        is_motorista: false,
      },
      grupo: null,
      perfis: null,
      usernameJaCadastrado: false,
      emailJaCadastrado: false,
      cpfJaCadastrado: false,
      cnpjJaCadastrado: false,
      isInvalid: true,
      mask: {
        required: false,
        type: String,
      },
      nacionalidades: [
        { pais: 'Brasil' },
        { pais: 'Paraguai' },
        { pais: 'Argentina' },
        { pais: 'Uruguay' },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('gerencial', ['grupos']),
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),

    perfisEmpresaAtual() {
      return Object.entries(Perfil)
        .map(([nome, cod]) => ({
          nome,
          cod,
        }))
        .filter((i) => this.empresaAtual.tiposContrato.includes(i.cod))
    },
  },
  async mounted() {
    if (!this.cadastroAdminEmpresa) {
      await this.getGrupos()
    }
  },
  methods: {
    ...mapActions('gerencial', ['getGrupos']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    close: function () {
      this.$emit('close')
    },

    async save(necessityEmmit = true) {
      if (!this.validate()) {
        return
      }

      if (this.usuario.cpf === '') {
        delete this.usuario.cpf
      }

      if (this.usuario.cnpj === '') {
        delete this.usuario.cnpj
      }

      if (!this.empresaAtual.public_id) {
        this.errorMessage('Houve um erro no cadastro!')
        return
      }

      if (this.usuario.cpf) this.usuario.cpf = cpf.strip(this.usuario.cpf)
      if (this.usuario.cnpj) this.usuario.cnpj = cnpj.strip(this.usuario.cnpj)

      delete this.usuario.avatar

      this.usuario.username = this.usuario.email

      if (necessityEmmit)
        this.$emit('save', {
          user: this.usuario,
          grupo: this.grupo,
          perfis: this.perfis,
          tenantAdminEmpresa: this.tenantAdminEmpresa,
          tipo: this.tipo,
        })
      else return { user: this.usuario, grupo: this.grupo }
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    async verificaDadoCadastradoChange(valor, campo) {
      if (valor) {
        if (campo === 'cpf') valor = cpf.strip(valor)
        else if (campo === 'cnpj') valor = cnpj.strip(valor)

        const params = { [campo]: valor }
        this[`${campo}JaCadastrado`] = await this.verificaDadoCadastrado(params)
      } else {
        this[`${campo}JaCadastrado`] = false
      }
    },
    changeTypeMask(nacionalidade) {
      switch (nacionalidade) {
        case 'Brasil':
          this.mask = '###.###.###-##'
          break
        case 'Paraguai':
          this.mask = '#######'
          break
        case 'Argentina':
          this.mask = '##.###.###'
          break
        case 'Uruguay':
          this.mask = '#.###.###.#'
          break
        default:
          this.mask = '###########'
      }
    },
    async verificaDadoCadastrado(params) {
      const data = await UsuarioApi.getUsuarioByParams(params)
      const result = data.length > 0

      if (result) {
        this.usuario.email = data[0].email
        this.usuario.name = data[0].name
      }

      return result
    },
  },
}
</script>
