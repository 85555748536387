var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"px-12",attrs:{"readonly":_vm.disabled}},[_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-row',{staticClass:"d-flex justify-center w-100"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.nacionalidades,"label":"Nacionalidade","item-text":"pais","item-value":"pais"},on:{"blur":function($event){return _vm.changeTypeMask(_vm.nacionalidade)}},model:{value:(_vm.nacionalidade),callback:function ($$v) {_vm.nacionalidade=$$v},expression:"nacionalidade"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"Documento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"label":"Documento","error-messages":errors,"required":""},on:{"change":function($event){return _vm.verificaDadoCadastradoChange($event, 'cpf')}},model:{value:(_vm.usuario.cpf),callback:function ($$v) {_vm.$set(_vm.usuario, "cpf", $$v)},expression:"usuario.cpf"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","disabled":_vm.disabled,"error-messages":_vm.emailJaCadastrado
                      ? 'Já existe uma conta ssociada a esse email'
                      : errors},on:{"change":function($event){return _vm.verificaDadoCadastradoChange($event, 'email')}},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Nome Completo","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome Completo","disabled":_vm.disabled,"error-messages":errors},model:{value:(_vm.usuario.name),callback:function ($$v) {_vm.$set(_vm.usuario, "name", $$v)},expression:"usuario.name"}})]}}])})],1)],1),(!_vm.cpfJaCadastrado)?_c('password',{staticClass:"d-flex justify-center w-100",attrs:{"label":"Senha","disabled":_vm.disabled,"cols":12,"sm":12,"md":6},model:{value:(_vm.usuario.password),callback:function ($$v) {_vm.$set(_vm.usuario, "password", $$v)},expression:"usuario.password"}}):_vm._e(),(!_vm.cadastroAdminEmpresa)?_c('v-row',{staticClass:"d-flex justify-center w-100"},[_c('v-col',{staticClass:"m-auto",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Grupo","items":_vm.grupos,"error-messages":errors,"item-text":"nome","item-value":"public_id"},model:{value:(_vm.grupo),callback:function ($$v) {_vm.grupo=$$v},expression:"grupo"}})]}}],null,false,904909175)})],1),_c('v-col',{staticClass:"m-auto",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Perfis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","label":"Perfis","items":_vm.perfisEmpresaAtual,"error-messages":errors,"item-text":"nome","item-value":"cod"},model:{value:(_vm.perfis),callback:function ($$v) {_vm.perfis=$$v},expression:"perfis"}})]}}],null,false,2276532141)})],1)],1):_vm._e()],1)],1)],1)],1),(_vm.hasButton)?_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":!(_vm.$refs.observer && _vm.$refs.observer.flags.valid)},on:{"close":_vm.close,"save":_vm.save}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }