import axios from 'axios'
import { _app } from "@/main"

class UtilsApi {

  async getSolicitacoesBaixa() {
    // const { data } = await axios.get('/api/')
  }

  async getEstadosBrasil() {
    const { data } = await axios.get(`api/v1/ufs`)
    data.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
    return data
  }

  async getCidadesEstado(uf) {
    const { data } = await axios.get(`/api/v1/ufs/${uf}/municipios/`);
    return data;
  }

  traduzirCamposInvalidos(erros) {
    const camposTraduzidos = {}

    Object.entries(erros).forEach(([campo, mensagens]) => {
      const campoTraduzido = _app.$t(`fields.${campo}`)

      camposTraduzidos[campoTraduzido] = mensagens
    })

    return camposTraduzidos
  }

}

export default new UtilsApi()
