var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-dialog',{on:{"confirm":_vm.confirm}}),(_vm.formAdicionarAdminEmpresa)?_c('v-card',[_c('FormFieldsAdicionarUsuario',{attrs:{"cadastroAdminEmpresa":""},on:{"close":function($event){_vm.formAdicionarAdminEmpresa = false},"save":_vm.adicionarContaAdministrativa}})],1):_c('div',{staticClass:"mt-4"},[(_vm.canList)?_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#ativos"},on:{"change":function($event){return _vm.$emit('update')}}},[_vm._v("Ativos")]),_c('v-tab',{attrs:{"href":"#inativos"},on:{"change":function($event){return _vm.$emit('updateInativos')}}},[_vm._v("Inativos")]),_c('v-tabs-items',{staticClass:"mt-1",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"ativos"}},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.ativos,"search":_vm.search,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":function($event){return _vm.$emit('update')}}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),(_vm.canCreate && _vm.allowedToAdd)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('mostrarFormAdicionar')}}},[_vm._v(_vm._s(_vm.titleNew))]):_vm._e()],1)]},proxy:true},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.cnpj,'##.###.###/####-##'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canUpdate)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account-supervisor-circle")]):_vm._e()]}}],null,true)},[(item.admins && item.admins.length > 0)?_c('v-list',_vm._l((item.admins),function(admin){return _c('v-list-item',{key:admin.user.id},[_c('v-list-item-title',[_vm._v(_vm._s(admin.user.email))])],1)}),1):_c('v-list',[_c('v-list-item',[_vm._v("Sem contas administrativas")]),_c('v-list-item',{on:{"click":function($event){return _vm.abrirDialogoAdicionarConta(item)}}},[_c('v-btn',{attrs:{"icon":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deactivateItem(item)}}},[_vm._v("mdi-cancel")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1),_c('v-tab-item',{attrs:{"value":"inativos"}},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.inativos,"search":_vm.search,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"medium":"","title":"Atualizar"},on:{"click":function($event){return _vm.$emit('updateInativos')}}},[_vm._v("mdi-refresh")]):_vm._e()],1)]},proxy:true},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.cnpj,'##.###.###/####-##'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.activateItem(item)}}},[_vm._v("mdi-undo")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }