var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"},[_vm._v(" Sobre a empresa ")]),(!_vm.novaEmpresa)?_c('span',{staticClass:"red--text"},[_vm._v("Dados editáveis apenas pelo administrador da empresa")]):_vm._e()]),_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"readonly":!_vm.novaEmpresa}},[_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Nome Fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome Fantasia","error-messages":errors,"disabled":_vm.disabled,"required":""},model:{value:(_vm.empresa.business_name),callback:function ($$v) {_vm.$set(_vm.empresa, "business_name", $$v)},expression:"empresa.business_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Razão Social","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.trade_name),callback:function ($$v) {_vm.$set(_vm.empresa, "trade_name", $$v)},expression:"empresa.trade_name"}})]}}])})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","lg":"6"}},[(!!_vm.empresa.cnpj)?_c('validation-provider',{attrs:{"name":"CNPJ","rules":"required|cnpjValido|cnpjNaoEmpresaAtual"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##.###.###/####-##']),expression:"['##.###.###/####-##']"}],attrs:{"label":"CNPJ","error-messages":errors,"readonly":"","disabled":""},model:{value:(_vm.empresa.cnpj),callback:function ($$v) {_vm.$set(_vm.empresa, "cnpj", $$v)},expression:"empresa.cnpj"}})]}}],null,false,1522206485)}):_c('validation-provider',{attrs:{"name":"CPF"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"label":"CPF","error-messages":errors,"readonly":"","disabled":""},model:{value:(_vm.empresa.cpf),callback:function ($$v) {_vm.$set(_vm.empresa, "cpf", $$v)},expression:"empresa.cpf"}})]}}])}),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"IE"},model:{value:(_vm.empresa.ie),callback:function ($$v) {_vm.$set(_vm.empresa, "ie", $$v)},expression:"empresa.ie"}})],1)],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('h3',{staticClass:"subtitle blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Endereço ")]),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Endereço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Endereço","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.address),callback:function ($$v) {_vm.$set(_vm.empresa, "address", $$v)},expression:"empresa.address"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Número","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.numero),callback:function ($$v) {_vm.$set(_vm.empresa, "numero", $$v)},expression:"empresa.numero"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Bairro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bairro","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.district),callback:function ($$v) {_vm.$set(_vm.empresa, "district", $$v)},expression:"empresa.district"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Complemento","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Complemento","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.complemento),callback:function ($$v) {_vm.$set(_vm.empresa, "complemento", $$v)},expression:"empresa.complemento"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Cidade","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.city),callback:function ($$v) {_vm.$set(_vm.empresa, "city", $$v)},expression:"empresa.city"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"CEP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#####-###']),expression:"['#####-###']"}],attrs:{"label":"CEP","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.zip_code),callback:function ($$v) {_vm.$set(_vm.empresa, "zip_code", $$v)},expression:"empresa.zip_code"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Estado","error-messages":errors,"items":_vm.estados,"disabled":_vm.disabled,"item-text":"nome","item-value":"nome"},model:{value:(_vm.empresa.state),callback:function ($$v) {_vm.$set(_vm.empresa, "state", $$v)},expression:"empresa.state"}})]}}])})],1),_c('v-col',{staticClass:"12",attrs:{"sm":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####', '(##) ####-####']),expression:"['(##) #####-####', '(##) ####-####']"}],attrs:{"label":"Telefone","error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.empresa.phone),callback:function ($$v) {_vm.$set(_vm.empresa, "phone", $$v)},expression:"empresa.phone"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex justify-center mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Numero RNTRC"},model:{value:(_vm.value.rntrc_numero),callback:function ($$v) {_vm.$set(_vm.value, "rntrc_numero", $$v)},expression:"value.rntrc_numero"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"label":"Tipo RNTRC","items":['CTC', 'ETC', 'TAC']},model:{value:(_vm.value.rntrc_tipo),callback:function ($$v) {_vm.$set(_vm.value, "rntrc_tipo", $$v)},expression:"value.rntrc_tipo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Data de validade RNTRC","persistent-hint":"","type":"date"},model:{value:(_vm.value.rntrc_expiration_date),callback:function ($$v) {_vm.$set(_vm.value, "rntrc_expiration_date", $$v)},expression:"value.rntrc_expiration_date"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }