<template>
  <v-card-text class="pa-5 border-bottom">
    <validation-observer ref="observer">
      <v-form>
        <v-row>
          <v-col cols="12" sm="12" lg="12" class="pt-0">
            <validation-provider
              v-slot="{ errors }"
              name="Documento"
              :rules="{
                required: true,
                cpfOuCnpjValido: true,
              }"
            >
              <v-text-field
                label="Documento"
                :error-messages="errors"
                required
                :disabled="disabled"
                @input="$emit('input', $event)"
                v-model="cnpj"
                v-mask="['##.###.###/####-##', '###.###.###-##', '###.###.###']"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card-text>
</template>

<script>
import Perfil from '@/utils/perfil'

export default {
  name: 'EtapaCnpj',
  props: {
    disabled: {
      type: Boolean,
    },
    cnpjProp: {
      type: String,
    },
  },
  data() {
    return {
      cnpj: '',
      Perfil,
    }
  },
  methods: {
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      this.$emit('input', this.cnpj)
      return isValid
    },

    dadosEditar() {
      if (this.cnpjProp) {
        this.cnpj = this.cnpjProp
      }
    },
  },

  created() {
    this.dadosEditar()
  },
}
</script>
